
/* Neue Haas Unica */

@font-face {
    font-family: 'NeueHaasUnica-Thin';
    font-weight: normal;
    font-style: normal;
    src: local('NeueHaasUnica-Thin'), url(./fonts/NeueHaasUnica/NeueHaasUnica-Thin.ttf) format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'NeueHaasUnica-Thin-Italic';
    font-weight: normal;
    font-style: normal;
    src: local('NeueHaasUnica-Thin-Italic'), url(./fonts/NeueHaasUnica/NeueHaasUnica-ThinItalic.ttf) format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'NeueHaasUnica-ExtraLight';
    font-weight: normal;
    font-style: normal;
    src: local('NeueHaasUnica-ExtraLight'), url(./fonts/NeueHaasUnica/NeueHaasUnica-ExtraLight.ttf) format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'NeueHaasUnica-ExtraLight-Italic';
    font-weight: normal;
    font-style: normal;
    src: local('NeueHaasUnica-ExtraLight-Italic'), url(./fonts/NeueHaasUnica/NeueHaasUnica-ExtLtIta.ttf) format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'NeueHaasUnica-Light';
    font-weight: normal;
    font-style: normal;
    src: local('NeueHaasUnica-Light'), url(./fonts/NeueHaasUnica/NeueHaasUnica-Light.ttf) format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'NeueHaasUnica-Light-Italic';
    font-weight: normal;
    font-style: normal;
    src: local('NeueHaasUnica-Light-Italic'), url(./fonts/NeueHaasUnica/NeueHaasUnica-LightItalic.ttf) format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'NeueHaasUnica-Regular';
    font-weight: normal;
    font-style: normal;
    src: local('NeueHaasUnica-Regular'), url(./fonts/NeueHaasUnica/NeueHaasUnica-Regular.ttf) format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'NeueHaasUnica-Regular-Italic';
    font-weight: normal;
    font-style: normal;
    src: local('NeueHaasUnica-Regular-Italic'), url(./fonts/NeueHaasUnica/NeueHaasUnica-Italic.ttf) format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'NeueHaasUnica-Medium';
    font-weight: normal;
    font-style: normal;
    src: local('NeueHaasUnica-Medium'), url(./fonts/NeueHaasUnica/NeueHaasUnica-Medium.ttf) format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'NeueHaasUnica-Medium-Italic';
    font-weight: normal;
    font-style: normal;
    src: local('NeueHaasUnica-Medium-Italic'), url(./fonts/NeueHaasUnica/NeueHaasUnica-MediumItalic.ttf) format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'NeueHaasUnica-Bold';
    font-weight: normal;
    font-style: normal;
    src: local('NeueHaasUnica-Bold'), url(./fonts/NeueHaasUnica/NeueHaasUnica-Bold.ttf) format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'NeueHaasUnica-Bold-Italic';
    font-weight: normal;
    font-style: normal;
    src: local('NeueHaasUnica-Bold-Italic'), url(./fonts/NeueHaasUnica/NeueHaasUnica-BoldItalic.ttf) format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'NeueHaasUnica-ExtraBold';
    font-weight: normal;
    font-style: normal;
    src: local('NeueHaasUnica-ExtraBold'), url(./fonts/NeueHaasUnica/NeueHaasUnica-ExtraBold.ttf) format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'NeueHaasUnica-ExtraBold-Italic';
    font-weight: normal;
    font-style: normal;
    src: local('NeueHaasUnica-ExtraBold-Italic'), url(./fonts/NeueHaasUnica/NeueHaasUnica-ExtraBoldItalic.ttf) format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'NeueHaasUnica-Black';
    font-weight: normal;
    font-style: normal;
    src: local('NeueHaasUnica-Black'), url(./fonts/NeueHaasUnica/NeueHaasUnica-Black.ttf) format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'NeueHaasUnica-ExtraBlack';
    font-weight: normal;
    font-style: normal;
    src: local('NeueHaasUnica-ExtraBlack'), url(./fonts/NeueHaasUnica/NeueHaasUnica-ExtraBlack.ttf) format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'NeueHaasUnica-ExtraBlack-Italic';
    font-weight: normal;
    font-style: normal;
    src: local('NeueHaasUnica-ExtraBlack-Italic'), url(./fonts/NeueHaasUnica/NeueHaasUnica-ExtBlkIta.ttf) format('truetype');
    font-display: swap;
}

/* Grenette */

@font-face {
    font-family: 'Grenette-Light';
    font-weight: normal;
    font-style: normal;
    src: local('Grenette-Light'), url(./fonts/GrenetteTrial/grenette-light-trial.otf) format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'Grenette-Light-Italic';
    font-weight: normal;
    font-style: normal;
    src: local('Grenette-Light-Italic'), url(./fonts/GrenetteTrial/grenette-light-italic-trial.otf) format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'Grenette-Regular';
    font-weight: normal;
    font-style: normal;
    src: local('Grenette-Regular'), url(./fonts/GrenetteTrial/grenette-regular-trial.otf) format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'Grenette-Regular-Italic';
    font-weight: normal;
    font-style: normal;
    src: local('Grenette-Regular-Italic'), url(./fonts/GrenetteTrial/grenette-italic-trial.otf) format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'Grenette-Semibold';
    font-weight: normal;
    font-style: normal;
    src: local('Grenette-Semibold'), url(./fonts/GrenetteTrial/grenette-semibold-trial.otf) format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'Grenette-Semibold-Italic';
    font-weight: normal;
    font-style: normal;
    src: local('Grenette-Semibold-Italic'), url(./fonts/GrenetteTrial/grenette-semibold-italic-trial.otf) format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'Grenette-Bold';
    font-weight: normal;
    font-style: normal;
    src: local('Grenette-Bold'), url(./fonts/GrenetteTrial/grenette-bold-trial.otf) format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'Grenette-Bold-Italic';
    font-weight: normal;
    font-style: normal;
    src: local('Grenette-Bold-Italic'), url(./fonts/GrenetteTrial/grenette-bold-italic-trial.otf) format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'Grenette-Black';
    font-weight: normal;
    font-style: normal;
    src: local('Grenette-Black'), url(./fonts/GrenetteTrial/grenette-black-trial.otf) format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'Grenette-Black-Italic';
    font-weight: normal;
    font-style: normal;
    src: local('Grenette-Black-Italic'), url(./fonts/GrenetteTrial/grenette-black-italic-trial.otf) format('opentype');
    font-display: swap;
}