@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background: #021F2B;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
.scrollbar::-webkit-scrollbar-thumb {
  background: #D9D9D9;
  border-radius: 10px;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #D9D9D9;
}

input {
  color: #001E2B;
}
input::placeholder {
  color: #B3B6B8;
}

textarea {
  color: #001E2B;
}
textarea::placeholder {
  color: #B3B6B8;
}

select{
  background: url(./assets/icons/arrow.svg) no-repeat calc(100% - 16px) 22px;
}
select:invalid {
  color: #B3B6B8;
}
option {
  color: #001E2B;
}

.header {
  font-family: Grenette-Regular, serif;
}

/* carousel dots */
.slick-dots {
  bottom: -50px !important;
}
.slick-dots li button:before {
  opacity: 100 !important;
  color: #183744 !important;
  font-size: 10px !important;
}
.slick-dots li.slick-active button:before {
  opacity: 100 !important;
  color: #80E88A !important;
}
.slick-prev, .slick-next {
  top: 60% !important;
  width: 52px !important;
  height: 52px !important;
  border: 1px solid #E5E5E5 !important;
  border-radius: 52px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.slick-next {
  right: -70px !important;
}
.slick-prev {
  left: -70px !important;
}